import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import logo from "./logo.svg";
import "./App.css";

import { createWeb3Modal } from "@web3modal/wagmi/react";
import { defaultWagmiConfig } from "@web3modal/wagmi/react/config";
import { useEffect, useState } from "react";
import { mainnet, bscTestnet, bsc } from "@wagmi/core/chains";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import {
  http,
  createConfig,
  WagmiProvider,
  useAccount,
  useConnect,
} from "wagmi";
import { injected, metaMask, safe, walletConnect } from "wagmi/connectors";

// import { config } from "./components/NFTsection/config.js";

// 0. Setup queryClient
const queryClient = new QueryClient();

// 1. Get projectId at https://cloud.walletconnect.com
const projectId = "2f3a5d0f85eec82617b8eeefb2fc9959";

export function hexToDecimal(hexString) {
  return parseInt(hexString, 16);
}
export const config = createConfig({
  chains: [mainnet, bsc, bscTestnet],
  transports: {
    [mainnet.id]: http(),
    [bsc.id]: http(),
    [bscTestnet.id]: http(),
  },
  connectors: [injected(), walletConnect({ projectId }), metaMask(), safe()],
  ssr: true,
});

createWeb3Modal({
  wagmiConfig: config,
  projectId,
  enableAnalytics: true, 
  enableOnramp: true,
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <App config={config} />
      </QueryClientProvider>
    </WagmiProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
