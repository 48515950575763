import React, { useEffect } from "react";

import { ToastContainer } from "react-toastify";
import RingLoader from "react-spinners/RingLoader";
import {
  http,
  createConfig,
  WagmiProvider,
  useAccount,
  useConnect,
} from "wagmi";
import "./styles.css";
import "./globalStyles.css";
import { NftHoldings } from "../NFTHoldings";
import NftSection from "../NFTsection";

const MainContainer = ({ params }) => {
  const { account } = params;

  const checkAccount = useAccount({
    onConnect({ address, connector, isReconnected }) {
      console.log("Connected", { address, connector, isReconnected });
    },
    onDisconnect() {
      console.log("Disconnected");
    },
  });

  return (
    <div>
      <div className="profile-page sidebar-collapse">
        <div
          className="wrapper"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "48px",
            marginRight: "36px",
          }}
        >
          <w3m-button />
        </div>
        <div style={{ textAlign: "center" }}>
          <h1 style={{textTransform: "uppercase"} }>Mint Baby Elon Wif</h1>
        </div>
        <NftSection
          tokenBalance={params.tokenBalance}
          //   mintNft={params.mintNft}
          isConnected={account.isConnected}
          yourYield={params.yourYield}
          totalYield={params.totalYield}
                  transactionHash={params.transactionHash}
                  nftHoldingIds={params.nftHoldingIds}
          params={params}
        />
        <div style={{ textAlign: "center" }}>
          <h1 style={{textTransform: "uppercase"} }>Your NFT Holdings</h1>
        </div>
              <NftHoldings params={params} nftHoldingIds={params.nftHoldingIds} />
        <ToastContainer
          variant="warning"
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
      {params.loading && (
        <div class="loader-container">
          <div class="loader-element">
            <RingLoader
              color={"white"}
              loading={params.loading}
              size={150}
              aria-label="Loading Spinner"
              cssOverride={{
                margin: "0 auto 42px auto",
              }}
              data-testid="loader"
            />
            <h1>Loading..</h1>
          </div>
        </div>
      )}
    </div>
  );
};

export default MainContainer;
