/* eslint-disable jsx-a11y/alt-text */
import React from "react";



export const NftHoldings = ({ params }) => {


  if (params && params.nftHoldingIds && params.nftHoldingIds.length) {

    return (
      <div style={{display: "flex", justifyContent: "center"}}>
        {params.nftHoldingIds.map((tokenId) => {
          return (
            <div class="nft-card nft-card--music">
              <div class="nft-card--header">
                <div class="nft-user" style={{ color: "white" }}>
                  BABYELONWIF #{tokenId}
                </div>
              </div>
              <div class="nft-card--content">
                <div class="wave" />
                <div class="wave" />
                <div class="wave" />
                <img src={params.baseUri + "/" + tokenId + ".jpg"} />
                <div class="nft-qrcode">
                  #{tokenId}
                  <span>/200</span>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
  return <h3>No NFT's detected.</h3>;
};
