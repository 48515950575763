import React from "react";
import { writeContract, readContract, getAccount } from "@wagmi/core";
import { contractABI, contractAddress } from "../../contractABI.js";
import telegram from "../../images/tg.webp";
import dex from "../../images/dex.png";
import { toast } from "react-toastify";
import { http, createConfig, useAccount, useConnect } from "wagmi";

import x from "../../images/x.png";
import "react-toastify/dist/ReactToastify.css";
import { config } from "../../index.js";

const NftSection = ({ params }) => {
  const { connect, connectors, error, isLoading, pendingConnector } =
    useConnect();
  const account = useAccount({
    onConnect({ address, connector, isReconnected }) {
    },
    onDisconnect() {
      console.log("Disconnected");
    },
  });

  const mint = async () => {
    let nftPrice = await readContract(config, {
      address: contractAddress,
      abi: contractABI,
      functionName: "cost",
    });

    params.setLoading(true);
    writeContract(config, {
      abi: contractABI,
      address: contractAddress,
      functionName: "mint",
      args: [params.account.address, 1],
      value: nftPrice,
    })
      .then((resp) => {
        params.setTransactionHash(resp);
        toast.success(`Your tx hash is - ${resp}`);
        params.setLoading(false);
        console.log(resp);
      })
      .catch((err) => {
        console.log(err);
        params.setLoading(false);
        toast.warning("Please report: " + err.message);
      });
  };
  return (
    <>
      {params.totalMinted && (
        <h2>{parseInt(params.totalMinted, 10).toString()} / 100 Minted</h2>
      )}
      <div class="nft-profile ">
        {/* <div class="nft-profile--header"></div> */}
        <div class="nft-profile--content">
          <div class="nft-user">
            <img src="https://babyelonwif.com/wp-content/uploads/2024/03/tokenomics_baby-elon-wif.png" />
          </div>

          <img src="https://miro.medium.com/max/960/0*9GkThfRr7h_iXyPr.gif" />

          <div class="nft-info">
            <h3>
              Baby Elon Wif
              <span>@babyelonwif</span>
            </h3>
          </div>

          <div class="nft-action">
            <input
              type="button"
              class="btn btn--secondary"
              value="MINT PRICE - 1BNB / mint"
            />
          </div>
          <div class="nft-action">
            <input
              type="button"
              class="btn btn--primary"
              value="Mint Now"
              onClick={() => mint()}
            />
          </div>

          <ul
            class="social-icons"
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              marginTop: "48px",
            }}
          >
            <li>
              <a href="https://t.me/BabyElonWif" target="_blank">
                <img
                  style={{ width: "50px", height: "50px", marginRight: "20px" }}
                  src={telegram}
                  alt="Telegram"
                />
              </a>
            </li>
            {/* <li>
              <a href="#" target="_blank">
                <img
                  style={{ width: "50px", height: "50px", marginRight: "20px" }}
                  src={dex}
                  alt="DexTools"
                />
              </a>
            </li> */}
            <li>
              <a href="https://twitter.com/BabyElonWif" target="_blank">
                <img
                  style={{ width: "50px", height: "50px", marginRight: "20px" }}
                  src={x}
                  alt="Twitter"
                />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default NftSection;
