import "./App.css";

import { useEffect, useState } from "react";

import { http, createConfig, useAccount, useConnect } from "wagmi";

import { readContract } from "@wagmi/core";
import { contractABI, contractAddress } from "./contractABI";
import MainContainer from "./components/mainContainer";
import { config } from "./index.js";

export function hexToDecimal(hexString) {
  return parseInt(hexString, 16);
}

function App() {
  const { connect, connectors, error, isLoading, pendingConnector } =
    useConnect();
  const [nftHoldingIds, setNftIds] = useState("");

  const [baseUri, setBaseUri] = useState("");
  const [totalMinted, setTotalMinted] = useState("");

  // const [account, setAccount] = useState({ isConnected: false });
  const [transactionHash, setTransactionHash] = useState("");
  const [loading, setLoading] = useState(false);


  
  const account = useAccount({
    onConnect({ address, connector, isReconnected }) {
      connect({ connector: connectors[0] });
      getNftBalance();
    },
    onDisconnect() {
      console.log("Disconnected");
      getNftBalance();
    },
  });

  const resetSetup = () => {};
  const setBalances = async () => {
    const provider = await connectors[0].getProvider();
    
    if (account && account.address) {
      getNftBalance();
      getUri();
    } else {
      resetSetup();
    }
  };
  useEffect(() => {
    setBalances();
  }, [account && account.isConnected]);

  useEffect(() => {
    // const getTotalMinted = async () => {
    //   let totalMinted = await readContract(config, {
    //     address: contractAddress,
    //     abi: contractABI,
    //     functionName: "totalSupply",
    //   });
    //   debugger;
    //   setTotalMinted(totalMinted);
    // }
    // getTotalMinted();
    // if (!account.isConnected) {
    //   try {
    //     connect({ connector: connectors[0] });
    //   } catch (e) {
    //     // ...
    //   }
    // }
  }, []);

  async function getUri() {
    const URIwithQuotes = await readContract(config, {
      abi: contractABI,
      address: contractAddress,
      functionName: "baseURI",
    });

    let URI = URIwithQuotes.replace(/[”“]/g, "");
    setBaseUri(URI);
  }

  async function getNftBalance() {
    if (!account.address) return;
    const balance = await readContract(config, {
      address: contractAddress,
      abi: contractABI,
      args: [account.address],
      functionName: "walletOfOwner",
    });
      let totalMinted = await readContract(config, {
        address: contractAddress,
        abi: contractABI,
        functionName: "totalSupply",
      });
      setTotalMinted(totalMinted);
    const tokenIdsForUser = balance.map((bal) => hexToDecimal(bal));
    setNftIds(tokenIdsForUser);
  }

  const params = {
    baseUri,
    nftHoldingIds,
    account,
    isConnected: account ? account.isConnected : false,
    loading,
    transactionHash,
    setLoading,
    setTransactionHash,
    totalMinted
  };

  return (
    <div className="App">
      <MainContainer params={params} />
    </div>
  );
}

export default App;
